<template>
  <div>

  <v-card max-width="370" class="mx-auto mt-5" flat>
      <!-- Anzeige für database Fehler -->
      <Errors v-bind:errors="errors"/>

      <!-- Anzeige für sonstige Fehler.  Wird geprüft wenn From abgeschickt wird und dann ggf angezeigt-->
      <v-alert 
      v-if="errors.length"
      color="error"
      type="error">
        <div>
          <div v-for="error in errors" v-bind:key="error">{{error}}</div>
          Das Event wurde nicht geändert.
        </div>
      </v-alert>

      <!-- Dialog for Conflicts when adding a series -->
      <ConflictingEventsWarning 
        v-bind:datesOfSeriesWithConflicts="this.datesOfSeriesWithConflicts" 
        v-bind:dialogConflictsInSeries="this.dialogConflictsInSeries" 
        @addSeries=addSeries
        @cancelAddingSeries=cancelAddingSeries
      />

    <v-card-title>
      <h4>Serie bearbeiten</h4>
      <v-spacer/>
      <v-btn 
      color="error"
      depressed 
      x-small
      @click="fetchEventData"
      >
      zurücksetzen
      </v-btn>
    </v-card-title>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
    >
      <v-tab :key="'editInfos'">
        Infos
      </v-tab>
      <v-tab :key="'editDateRange'">
        Start/Ende
      </v-tab>

      <v-tabs-items v-model="tab">

        <v-tab-item :key="'editInfos'">
          <v-card-text>

            Wenn du die Einstellungen einer Serie hier änderst, werden automatisch alle zukünftigen Nachhilfestunden in
            dieser Serie geändert. <br> Hier werden die Standarteinstellungen der Serie angezeigt. 
            Alle Änderungen die an einzelnen Stunden vorgenommen wurden, werden überschrieben.

            <v-form 
              class="mt-6"
              @submit.prevent
              v-model="valid"
              ref="editSeriesForm">

              <!-- Wochentag Feld -->
              <v-autocomplete
          :items = weekdays
          label = "Wochentag"
          item-text="name"
          return-object
          v-model.trim="weekday"
          outlined
          :rules="requiredRule"
          required
          class="mt-2"
              />

                <!-- Startzeit Feld -->
              <v-text-field 
          type="time"
          label="Begin"
          v-model.trim="start"
          :rules="timeRule"
          @input="setEndTime"
          required
              />

              <!-- Endzeit Feld -->
              <v-text-field 
          type="time"
          label="Ende"
          v-model.trim="end"
          :rules="timeRule"
          required
              />

              <!-- Lehrerfeld neu -->
              <v-select
          :items="this.teachers"
          item-text="name"
          return-object
          label="Lehrer"
          v-model.trim="teacher"
          outlined
          :rules="requiredRule"
          required
              />

              <!-- Student field -->
              <v-text-field
                label="Schüler"
                v-model.trim="student.name"
                readonly
                outlined
                :rules="requiredRule"
                required
              />

              <!-- Raumauswahl -->
              <v-select
          :items="this.rooms"
          item-text="name"
          return-object
          label="Raum"
          v-model="room"
          outlined
          :rules="requiredRule"
          required
              />

              <!-- Fachauswahl -->
              <v-combobox
          :items = "this.subjects"
          label="Fach"
          v-model.trim="subject"
          outlined
          :rules="requiredRule"
          required
          >
              </v-combobox>

            </v-form>

            <Warnings v-bind:warnings="[{text: 'Achtung! Frühere Änderungen werden überschrieben.'}]"/>
          </v-card-text>


          <v-card-actions class="mx-2 mt-n2">
            <v-btn 
            color="primary"
            depressed 
            block
            :disabled="!valid || !!endOfSeriesHint"
            @click="editSeries"
            >
              <div v-if="!loadEditSeries">
                Serie ändern
              </div>

              <v-progress-circular
                v-if="loadEditSeries"
                indeterminate
                color="white"
              />
            </v-btn>          
          </v-card-actions>

        </v-tab-item>

        <v-tab-item :key="'editDateRange'">
          <v-card-text>

            Wenn du die Dauer verlängerst, werden automatisch neue Stunden hinzugefügt. <br>
            Wenn du die Dauer verkürzt, werden die Stunden die davor, beziehungsweise danach, gekommen wären, gelöscht.

            <v-form 
              class="mt-6"
              @submit.prevent
              v-model="valid"
              ref="editStartForm"
            >
              <!-- Startdatumsfeld der Serie -->
              <v-text-field 
              type="date" 
              label="Startdatum"
              v-model.trim="startOfSeries"
              :disabled="startOfSeriesIsBeforeToday"
              :rules="dateRule"
              :hint="startOfSeriesHint"
              persistent-hint
              required
              />

            </v-form>
          </v-card-text>

          <v-card-actions class="mt-n5">
            <v-btn 
              color="primary"
              depressed 
              block
              :disabled="startOfSeriesIsBeforeToday"
              @click="editStartDate"
            >
              <div v-if="!loadEditStartOfSeries">
                ändern
              </div>
              <v-progress-circular
                v-if="loadEditStartOfSeries"
                indeterminate
                color="white"
              />
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <v-form 
              @submit.prevent
              v-model="valid"
              ref="editEndForm"
            >
              <!-- Enddatumsfeld der Serie -->
              <v-text-field 
              type="date" 
              label="Enddatum"
              v-model.trim="endOfSeries"
              :disabled="endOfSeriesIsBeforeToday"
              :rules="dateRule"
              :hint="endOfSeriesHint"
              persistent-hint
              required
              class="mt-8"
              />
            </v-form>
          </v-card-text>

          <v-card-actions class="mt-n5">
            <!-- Edit End Date button -->
            <v-btn 
              :disabled="endOfSeriesIsBeforeToday"
              color="primary"
              depressed 
              block
              @click="editEndDate"
            >
              <div v-if="!loadEditEndOfSeries">
                ändern
              </div>
              <v-progress-circular
                v-if="loadEditEndOfSeries"
                indeterminate
                color="white"
              />
            </v-btn>
          </v-card-actions>

        </v-tab-item>
      </v-tabs-items>

    </v-tabs>
  </v-card>

  </div>
</template>

<script>
import {mapState} from 'vuex';
import { db, auth, eventsCollection, teachersCollection, studentsCollection, usersCollection, seriesCollection } from '@/firebase';
import {calculateDateAtWeekday, addMinutes, addDays} from '@/assets/js/timeUtils';
import {checkForm, noConflicts, calculateDatesForSeries} from '@/assets/js/addEvent.js';
import {getInsituteSettings} from '@/assets/js/dataUtils.js';
import ConflictingEventsWarning from '@/components/ConflictingEventsWarning.vue';
import Errors from '@/components/Errors.vue';
import Warnings from '@/components/Warnings.vue';



export default {
    name: 'EditEvent',
    props: ['seriesId'],
    components: {
      ConflictingEventsWarning,
      Errors,
      Warnings,
    },
    data() {
        return {
          tab: 0, //0 means selecting the 'single' tab, 1 means the 'series' tab

          rooms: [],
          minEventDuration: 45,
          valid: false, //true if ':rules' are fullfilled in every field of the form. Only then button 'Hinzufügen' is clickable
          errors: [], //list of other custom errors
          dbErrors: [], //list of errors when getting douments form the db
          series: {},
          date: '',
          start: '',
          end: '',
          teacher: {},
          teachers: [],
          student: {},
          students: [],
          room: {},
          subject: '',
          startOfSeries: '',
          startOfSeriesIsBeforeToday: true,
          startOfSeriesHint: '',
          endOfSeries: '',
          endOfSeriesIsBeforeToday: true,
          endOfSeriesHint: '',
          weekday: {name: ''},
          weekdays: [{name: 'Montag', index: 1}, {name: 'Dienstag', index: 2}, {name: 'Mittwoch', index: 3}, {name: 'Donnerstag', index: 4}, {name: 'Freitag', index: 5}, {name: 'Samstag', index: 6}, {name: 'Sonntag', index: 0}],

          datesForSeries: [], //needed to add events when elongening the series (editStart/EndDate)
          datesOfSeriesWithConflicts: [], //needed to add events when elongening the series (editStart/EndDate)
          editType: '',
          loadEditSeries: false,
          loadEditStartOfSeries: false,
          loadEditEndOfSeries: false,
          dialogConflictsInSeries: false,

          /* the following are displayed as list to choose from in the form */
          subjects: [
            'Deutsch', 'Englisch', 'Mathe'
          ],

          /* rules for the form */
          requiredRule: [
            v => !!v || 'Pflichtfeld',
          ],
          timeRule: [ //checks if the input is a string of format 'hh:mm'
            v => /([0-1][0-9]|2[0-3]):[0-5]\d/.test(v) || "Gib eine gültige Uhrzeit ein."
          ],
          dateRule: [ //checks if the input is a string of format 'yyyy-mm-dd' and a valid date. Caution! does not test for leap years. The 29.02 is always valid.
            v => /\d{4}-(02-[0-2]\d|(01|03|05|07|08|10|12)-([0-2]\d|3[0-1])|(04|06|09|11)-([0-2]\d|30))/.test(v) || "Gib ein gültiges Datum im Format 'yyyy-mm-dd' an."
          ]
        }
    },
    computed: {
      ...mapState(['userProfile']),
    },
    async created() {
      //get instituteId of currently logged in user
      this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

      this.getSettings();
      this.getTeachers();
      this.fetchEventData();
    },
 
    methods: {

      /**
       * @description fetches the data of the event with the id from the route from firestore
       */
      async fetchEventData(){
        try {
          const seriesDoc = await seriesCollection.doc(this.seriesId).get();
          this.series = seriesDoc.data();
          this.series.id = seriesDoc.id;
          this.date = this.series.date;
          this.start = this.series.start;
          this.end = this.series.end;
          this.room = {'name': this.series.room, 'id': this.series.roomId};
          this.subject = this.series.subject; 
          this.startOfSeries = this.series.startOfSeries;
          this.endOfSeries = this.series.endOfSeries;
          this.weekday = this.series.weekday;

          //get name of the teacher from teacherRef
          const teacherdoc = await this.series.teacherRef.get();
          if (teacherdoc.exists){
            let teacherName = teacherdoc.data().firstname + ' ' + teacherdoc.data().lastname;
            this.teacher = {name: teacherName, ref: this.series.teacherRef};
          } else {
            this.errors.push({
              text: 'Die Lehrerin / der Lehrer konnte nicht aus der Datenbank geladen werden.', 
              type:'firestore',
            })
            // scroll to beginning of page to display warning
            window.scrollTo(0,0);
            throw "Could not find this doument in firestore";
          }

          //get the name of the student from studentRef
          const studentDoc = await this.series.studentRef.get();
          if (studentDoc.exists){
            let data = studentDoc.data()
            let studentName = data.firstname + ' ' + data.lastname;
            this.student = {
              name: studentName, 
              ref: this.series.studentRef, 
              year: data.year, 
              schooltype: data.schooltype,
              id: studentDoc.id,
            };
          } else {
            this.errors.push({
              text: 'Die Schülerin / der Schüler konnte nicht aus der Datenbank geladen werden.', 
              type:'firestore',
            })
            // scroll to beginning of page to display warning
            window.scrollTo(0,0);
            throw "Could not find this doument in firestore";
          }

          //if endOfSeries is after today, disable editing editing end of series
          if(this.endOfSeries >= new Date().toISOString().substr(0,10)){
            this.endOfSeriesIsBeforeToday = false;
          } else {
            this.endOfSeriesHint = "Kann nicht mehr geändert werden, da die Serie bereits beendet ist.";
          }

          //if startOfSeries is after today, enable editing
          if(this.startOfSeries >= new Date().toISOString().substr(0,10)){
            this.startOfSeriesIsBeforeToday = false;
          } else {
             this.startOfSeriesHint = "Kann nicht mehr geändert werden, da die Serie bereits angefangen hat.";
          }

        } catch(error) {
          console.log(error);
          this.errors.push({
            text: 'Die Daten konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
          // scroll to beginning of page to display warning
          window.scrollTo(0,0);
          throw "Could not find this doument in firestore";
        }
      },

      getTeachers() {
        teachersCollection
          .where("instituteId", "==", this.instituteId)
          .orderBy('firstname')
          .get()
          .then(snapshot => {
            this.teachers = [];
            snapshot.forEach(doc => {
              let teacherName = doc.data().firstname + ' ' + doc.data().lastname
              let teacherRef = teachersCollection.doc(doc.id);
              this.teachers.push({name: teacherName, ref: teacherRef});
            })
          }).catch((error) => {
            console.log("Error getting documents: ", error);
            this.dbErrors.push({
              text: 'Die Lehrer:innen konnten nicht aus der Datenbank geladen werden.', 
              type:'firestore',
            })
          });
      },

      /**
       * @description gets the instiute settings (min Duration of an Event, rooms etc) from firestore and setts this.rooms and this.room
       */
      async getSettings(){
        try {
          //get institute Settings from currently logged in user
          let settings = await getInsituteSettings();
          this.minEventDuration = settings.minEventDuration;
          this.rooms = settings.rooms;
          this.endOfPeriod = settings.endOfPeriod;
        } catch {
          this.dbErrors.push({
            text: 'Der Einstellungen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
          throw "Could not find this doument in firestore";
        }
      },

      /**
     * @description prefills the end time to: start time + minutes that an event has minimum (this.minEnventDuration)
     */
      setEndTime(){
        this.end = addMinutes(this.start, this.minEventDuration);
      },

      /**
       * @description 
       */
      async editSeries() {
        this.editType = 'edit';

        /* check if all inputs are valid (this.valid) and all the custom rules are valid (this.checkForm) */
        
        let formIsValid = false;
        [formIsValid, this.errors] = await checkForm(true, this.start, this.end, this.startOfSeries, this.endOfSeries);

        if(this.valid && formIsValid){ 
          
          try{
            this.loadEditSeries = true;

            /* Fetch all the other events from this series */ 
            let eventsReferences = this.series.eventReferences;
            let currentEvent = {};

            for (let i=0; i < eventsReferences.length; i++){
              try{
                const currentEventDoc = await eventsReferences[i].get();              
              
                currentEvent = currentEventDoc.data();
                currentEvent.id = currentEventDoc.id;

                /* Only update the events that are today or later */
                let today = new Date().toISOString().substr(0,10);
                if(currentEvent.date >= today){

                  /* calculate the new Date of the event */
                  let newDate = await calculateDateAtWeekday(currentEvent.date, this.weekday.index);
                  let editedEvent = {date: newDate, start: this.start, end: this.end, room: this.room, teacher: this.teacher, student: this.student};
                  let noConflictsBool = false;                  
                  [noConflictsBool, , ] = await noConflicts(editedEvent, [currentEvent.id]); //is true if there are no conflicting events

                  /* if there are no conflicts, add it to the array of dates to be added to firestore. Else add it two an array used for the warning message */
                  if(noConflictsBool){
                    this.datesForSeries.push({eventId: currentEvent.id, date: newDate});  //we also need to store the date. Else we would have to calculate it again
                  } else {
                    this.datesOfSeriesWithConflicts.push(newDate);
                  }
                }
              } catch {
                console.log("Could not find the event with id " + eventsReferences[i].id + " in firestore. It therefore remains unchanged.")
              }
            }

            /* if there are conflicts, display warning message. Else add events to db */
            if(this.datesOfSeriesWithConflicts.length){
              //display warning message
              this.dialogConflictsInSeries = true;
            } else {
              this.addSeries();
            }

          } catch {
            this.errors.push({
              text: 'Das Serie konnte nicht geändert werden.', 
              type:'firestore',
            })
            // scroll to beginning of page to display warning
            window.scrollTo(0,0);
            throw "Could not find this doument in firestore";

          } finally {
            this.loadEditSeries = false;
          }
          
        
        } else {
          /* scroll to beginning of page to display warning */
          window.scrollTo(0,0);
        }
      },

      async editStartDate() {
        this.datesForSeries = [];
        this.datesOfSeriesWithConflicts = [];
        this.dialogConflictsInSeries = false;
        this.editType = 'start';

        /* check if the start date is before, or after the the old startdate (stored in this.series) */
        if(this.series.startOfSeries < this.startOfSeries){ //eg. new date is after old date -> series is postponed
          /* delete all the events of the series, that are before the new date */
          //get all the events that are before the new Date
          let eventReferences = this.series.eventReferences;
          let updatedEventReferences = [];
          for(let i=0; i<eventReferences.length; i++){
            try{
              const currentEventDoc = await eventReferences[i].get();

              let dateToCheck = currentEventDoc.data().date;
              if(dateToCheck < this.startOfSeries){ //date is before the new date
                //delete this event
                await eventReferences[i].delete();
              } else {
                updatedEventReferences.push(eventReferences[i]);
              }
              
            } catch {
              this.errors.push({
                text: 'Das Startdatum konnte nicht geändert werden.', 
                type:'firestore',
              })
              // scroll to beginning of page to display warning
              window.scrollTo(0,0);
              throw "Could not find this doument in firestore";
            }
          }

          /* update startOfSeries and the eventRefs in the eventseries collection */
          await seriesCollection.doc(this.seriesId).update({
            startOfSeries: this.startOfSeries,
            eventReferences: updatedEventReferences,
          }).catch((error) => {
            console.log("Error updating documents: ", error);
            this.dbErrors.push({
              text: 'Das Startdatum konnte nicht geändert werden.', 
              type:'firestore',
            })
          });        

          this.redirectToSeriesView();

        } else if(this.series.startOfSeries > this.startOfSeries) { //eg. new date is before old date -> series was brought forward
          /* add new events that are before the old date */
          this.loadEditStartOfSeries = true;
          let datesToAdd = await calculateDatesForSeries(this.startOfSeries, addDays(this.series.startOfSeries,-1), this.weekday);
          
          /* for all the dates check if there are conflicts */
          for(let i = 0; i<datesToAdd.length; i++){
            let currentEvent = {date: datesToAdd[i], start: this.series.start, end: this.series.end, room: {name: this.series.room, id: this.series.roomId}, student: {ref: this.series.studentRef}, teacher: {ref: this.series.teacherRef}};
            let noConflictsBool = false;
            [noConflictsBool, , ] = await noConflicts(currentEvent); //is true if there are no conflicting events
            
            /* if there are no conflicts, add it to the array of dates to be added to firestore. Else add it two an array used for the warning message */
            if(noConflictsBool){
              this.datesForSeries.push(datesToAdd[i]);
            } else {
              this.datesOfSeriesWithConflicts.push(datesToAdd[i]);
            }
          }

          /* if there are conflicts, display warning message. Else add events to db */
          if(this.datesOfSeriesWithConflicts.length){
            //display warning message
            this.dialogConflictsInSeries = true;
          } else {
            await this.addSeries();
          }
          this.loadEditStartOfSeries = false;

        } else { //else do nothing. Because the date was not changed
          console.log('The date was not changed');
          this.errors.push({
            text: 'Es wurden keine Änderungen vorgenommen, da das Datum unveränder ist.'
          })
        } 
      },

      async editEndDate() {
        this.datesForSeries = [];
        this.datesOfSeriesWithConflicts = [];
        this.dialogConflictsInSeries = false;
        this.editType = 'end';

        /* check if the end date is before, or after the the old enddate (stored in this.series) */
        if(this.series.endOfSeries < this.endOfSeries){ //eg. new date is after old date -> series will be extended
          /* add new events that are after the old date */

          this.loadEditEndOfSeries = true;
          let datesToAdd = await calculateDatesForSeries(this.series.endOfSeries, this.endOfSeries, this.weekday);
          
          /* for all the dates check if there are conflicts */
          for(let i = 0; i<datesToAdd.length; i++){
            let currentEvent = {date: datesToAdd[i], start: this.series.start, end: this.series.end, room: {name: this.series.room, id: this.series.roomId}, student: {ref: this.series.studentRef}, teacher: {ref: this.series.teacherRef}};
            let noConflictsBool = false;
            [noConflictsBool, , ] = await noConflicts(currentEvent); //is true if there are no conflicting events
            
            /* if there are no conflicts, add it to the array of dates to be added to firestore. Else add it two an array used for the warning message */
            if(noConflictsBool){
              this.datesForSeries.push(datesToAdd[i]);
            } else {
              this.datesOfSeriesWithConflicts.push(datesToAdd[i]);
            }
          }

          /* if there are conflicts, display warning message. Else add events to db */
          if(this.datesOfSeriesWithConflicts.length){
            //display warning message
            this.dialogConflictsInSeries = true;
          } else {
            await this.addSeries();
          }
          this.loadEditEndOfSeries = false;

        } else if(this.series.endOfSeries > this.endOfSeries) { //eg. new date is before old date -> series will be shortened
          /* delete all the events of the series, that are after the new date */
          //get all the events that are after the new Date
          let eventReferences = this.series.eventReferences;
          let updatedEventReferences = [];
          for(let i=0; i<eventReferences.length; i++){
            try{ 
              const currentEventDoc = await eventReferences[i].get();
            
              let dateToCheck = currentEventDoc.data().date;
              if(dateToCheck > this.endOfSeries){ //date is after the new date
                //delete this event
                await eventReferences[i].delete();
              } else {
                updatedEventReferences.push(eventReferences[i]);
              }
            } catch {
              this.errors.push({
                text: 'Das Enddatum konnte nicht geändert werden.', 
                type:'firestore',
              })
              // scroll to beginning of page to display warning
              window.scrollTo(0,0);
              throw "Could not find this doument in firestore";
            }
          }

          /* update endOfSeries and the eventRefs in the eventseries collection */
          await seriesCollection.doc(this.seriesId).update({
            endOfSeries: this.endOfSeries,
            eventReferences: updatedEventReferences,
          }).catch((error) => {
            console.log("Error updating documents: ", error);
            this.dbErrors.push({
              text: 'Das Enddatum konnte nicht geändert werden.', 
              type:'firestore',
            })
          });
          this.redirectToSeriesView();
        } else {//else do nothing. Because the date was not changed
          console.log('The date was not changed');
          this.errors.push({
            text: 'Es wurden keine Änderungen vorgenommen, da das Datum unveränder ist.'
          })
        } 
      },

      /* used to update events of a series and add more events to a series, if the series is enloged due to editing the start- or enddate of the series */
      async addSeries(){

        if(this.datesForSeries.length){ //check if there are any dates to add

          if(this.editType == 'edit') {

            /* the type of an event is 'event' except from online-events, where the type is 'online'. */
            let type = 'event';
            if(this.room.name == 'Online'){
              type = 'online';
            }

            try{
              for(let i= 0; i<this.datesForSeries.length; i++){            
                await eventsCollection.doc(this.datesForSeries[i].eventId).update({
                  date: this.datesForSeries[i].date,
                  start: this.start,
                  end: this.end,
                  teacherRef: this.teacher.ref,
                  studentRef: this.student.ref,
                  room: this.room.name,
                  roomId: this.room.id,
                  subject: this.subject,
                  year: this.student.year,
                  school: this.student.schooltype,
                  type: type,
                })

              }

              await seriesCollection.doc(this.seriesId).update({
                weekday: this.weekday,
                start: this.start,
                end: this.end,
                teacherRef: this.teacher.ref,
                studentRef: this.student.ref,
                room: this.room.name,
                roomId: this.room.id,
                subject: this.subject,
                year: this.student.year,
                school: this.student.schooltype,
                type: type,
              })
            } catch(error) {
              this.dbErrors.push({
                text: 'Die Serie konnte nicht geändert werden.', 
                type:'firestore',
              })
              throw error;
            }

          } else {

            /* storage of the new created event refs. Used to write this array in eventSeries collection later */
            var eventReferences = this.series.eventReferences;

            try{

              /* add an event to firestore at every date in this.datesOfSeries */
              for (let i = 0; i < this.datesForSeries.length; i++){

                await eventsCollection.add({
                  instituteId: this.instituteId,
                  date: this.datesForSeries[i],
                  start: this.series.start,
                  end: this.series.end,
                  teacherRef: this.series.teacherRef,
                  studentRef: this.series.studentRef,
                  room: this.series.room,
                  roomId: this.series.roomId,
                  subject: this.series.subject,
                  year: this.series.year,
                  school: this.series.school,
                  type: this.series.type,
                  seriesReference: db.doc(seriesCollection.path + '/' + this.seriesId),
                })
                .then(docRef => {
                  /* add reference of the newly added document to the array */
                  eventReferences.push(docRef);
                }) 
              }

              /* add the array of references to eventSeries */ 
              if(this.editType == 'start'){
                await seriesCollection.doc(this.seriesId).update({
                  eventReferences: eventReferences,
                  startOfSeries: this.startOfSeries,
                })
              } else if(this.editType == 'end') {
                await seriesCollection.doc(this.seriesId).update({
                  eventReferences: eventReferences,
                  endOfSeries: this.endOfSeries,
                })
              }
            } catch(error) {
              this.dbErrors.push({
                text: 'Die Serie konnte nicht geändert werden.', 
                type:'firestore',
              })
              throw error;
            }
          }
          
        }
        
        this.redirectToSeriesView();
      },

      /* triggered when pressing chancel in ConflinctinEventsWarning Dialog */
      cancelAddingSeries() {
        this.dialogConflictsInSeries = false;
        this.datesForSeries = [];
        this.datesOfSeriesWithConflicts = [];
        this.editType = '';
      },

      redirectToAddStudent(){
        this.$router.push('/addstudent')
      },

      redirectToSeriesView(){
        this.$router.push('/series/' + this.series.id);
      }
    }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>