<template>
<div>
    <v-alert 
    v-if="warnings.length"
    class="text-body-2"
    color="accent"
    type="info"
    light
    tile>
      <div v-for="warning in warnings" v-bind:key="warning.text">
        {{warning.text}}
        {{warning.advice}}
      </div>
    </v-alert>
</div>
</template>

<script>
export default {
  name: 'Warnings',

  props: {
    warnings: { //ist ein Objekt mit den Attributen text, advice und type. Advice wird ggf erst in created gesetzt
      type: Array,
      default: () => []
    },
  },

  watch: {
    warnings: {
      immediate: true,
      handler() {
        /* Add advice texts for standart warnings */
        for(let i=0; i<this.warnings.length; i++){
          let warning = this.warnings[i];

          let hasAdviceText = Object.prototype.hasOwnProperty.call(warning, 'advice');
          if(warning.type == 'porceed' && !hasAdviceText){
            warning.advice = 'Du kannst trotzdem fortfahren.'
          }
        }
      }
    }
  },

}
</script>

<style>

</style>