<template>
  <v-row justify="center">
      <v-dialog
        v-model="showDialog"
        scrollable
      >
        <v-card>
          <v-card-title>Achtung: Es treten Konflikte auf.</v-card-title>
          <v-card-text>
            Die Serie kann nicht vollständig hinzugefügt / geändert werden.
            An folgenden Tagen traten Konflikte auf:
            <ul v-for="date in this.datesOfSeriesWithConflicts" v-bind:key="date">
              <li>{{date | formatDate }}</li>
            </ul>
            <br>
            Wenn du die Serie trotzdem hinzufügst / änderst, wird an diesen Tagen kein Event erstellt. 
            Beziehungsweise an diesen Tagen keine Änderung vorgenommen.
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row class="mb-2">
                <v-btn
                  @click="cancel"
                  color="error"
                  width="100%"
                >
                  Abbrechen
                </v-btn>
              </v-row>
              <v-row>
                <v-btn
                  @click="addSeries"
                  color="primary"
                  width="100%"
                >
                  Trotzdem hinzufügen
                </v-btn>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
</template>

<script>
export default {
  name: 'ConflictingEventsWarning',
  props: ['datesOfSeriesWithConflicts', 'dialogConflictsInSeries'],

  data() {
    return{
      showDialog: false,
    }
  },

  watch: {
    dialogConflictsInSeries() {
      this.showDialog = this.dialogConflictsInSeries;
    },
  }, 

  methods: {
    addSeries(){
      /* emits action to the parent, so that the function "addSeries" in the parent is called */
      this.$emit('addSeries');
    },

    cancel() {
      this.showDialog = false
      this.$emit('cancelAddingSeries')
    },
  }
}
</script>

<style>

</style>